import { datadogLogs } from "@datadog/browser-logs";
import { ChannelValue } from "./shared-customer-journey.types";
export var getParamValue = function(param) {
    var ref;
    if (!window.location) return null;
    var params = (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    var searchParams = new URLSearchParams(params);
    return searchParams.get(param);
};
export var getChannel = function() {
    var ref;
    if (!window.location) return ChannelValue.direct;
    var params = window.location.search;
    datadogLogs.logger.info("AWIN URL search params", {
        location: window.location,
        params: params
    });
    var searchParams = new URLSearchParams(params);
    var awc = searchParams.has("awc");
    var CID = (ref = searchParams.get("CID")) === null || ref === void 0 ? void 0 : ref.toLowerCase();
    var gclid = searchParams.has("gclid");
    var fbclid = searchParams.has("fbclid");
    var channelConditionsList = [
        {
            priority: 1,
            channelName: "ppcGeneric",
            condition: gclid && !(CID === null || CID === void 0 ? void 0 : CID.includes("brand")),
            channelValue: ChannelValue.ppcGeneric
        },
        {
            priority: 2,
            channelName: "ppcBrand",
            condition: gclid && !!(CID === null || CID === void 0 ? void 0 : CID.includes("brand")),
            channelValue: ChannelValue.ppcBrand
        },
        {
            priority: 3,
            channelName: "facebook",
            condition: fbclid && !!(CID === null || CID === void 0 ? void 0 : CID.includes("social")) && (CID.includes("meta") || CID.includes("facebook")),
            channelValue: ChannelValue.facebook
        },
        {
            priority: 4,
            channelName: "tiktok",
            condition: !!(CID === null || CID === void 0 ? void 0 : CID.includes("tiktok")),
            channelValue: ChannelValue.tiktok
        },
        {
            priority: 5,
            channelName: "awin",
            condition: awc,
            channelValue: ChannelValue.awin
        },
        {
            priority: 6,
            channelName: "direct",
            condition: !CID && !awc,
            channelValue: ChannelValue.direct
        }, 
    ];
    var channelConditionValues = channelConditionsList.filter(function(channelCondition) {
        return channelCondition.condition;
    });
    return channelConditionValues.length > 0 ? channelConditionValues.sort(function(a, b) {
        return a.priority - b.priority;
    })[0].channelValue : ChannelValue.direct;
};
export var getCookie = function(param) {
    var name = param.name;
    var ref;
    return (ref = document.cookie.match("(^|;)\\s*".concat(name, "\\s*=\\s*([^;]+)"))) === null || ref === void 0 ? void 0 : ref.pop();
};
export var cookieBuilder = function(key, data, expiryDay) {
    datadogLogs.logger.info("AWIN cookie data", {
        data: data
    });
    var expiryDate = new Date();
    var cookieValue = JSON.stringify(data);
    var daysToMilliseconds = expiryDay * 8.64e7;
    expiryDate.setTime(expiryDate.getTime() + daysToMilliseconds);
    var expires = "expires=".concat(expiryDate.toUTCString());
    return "".concat(key, "=").concat(cookieValue, ";").concat(expires, ";path=/");
};
// Will only work for objects that are one level deep
export var compareObjects = function(object1, object2) {
    for(var key in object1){
        // eslint-disable-next-line no-prototype-builtins
        if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
            return false;
        }
    }
    for(var key1 in object2){
        // eslint-disable-next-line no-prototype-builtins
        if (object2.hasOwnProperty(key1) && object1[key1] !== object2[key1]) {
            return false;
        }
    }
    return true;
};
