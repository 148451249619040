export function decorateOptions(options, count) {
    var shouldDecorate = options.findIndex(function(option) {
        return option.value === "any";
    }) === -1;
    if (shouldDecorate) {
        options.unshift({
            label: "Any",
            value: "any",
            count: "".concat(count)
        });
    }
    return options;
}
