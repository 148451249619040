import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { getSearchAPIData } from "./api";
export var getHomePageVehiclesData = function() {
    var _ref = _async_to_generator(function() {
        var res, makesData, makes, carTypes, monthlyPriceBucket, priceBucket, vehiclesData;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        getSearchAPIData()
                    ];
                case 1:
                    res = _state.sent();
                    makesData = res.facets.makes;
                    makes = makesData.map(function(make) {
                        return {
                            name: make.name,
                            stock: make.doc_count,
                            models: make.models.map(function(model) {
                                return {
                                    name: model.name,
                                    stock: model.doc_count
                                };
                            })
                        };
                    });
                    carTypes = _object_spread_props(_object_spread({}, res.facets.bodyType), {
                        electric: res.facets.fuelType.electric
                    });
                    monthlyPriceBucket = _object_spread({}, res.facets.monthlyPriceBucket);
                    priceBucket = _object_spread({}, res.facets.priceBucket);
                    vehiclesData = {
                        totalStock: res.searchResultsCount,
                        makes: makes,
                        carTypes: carTypes,
                        monthlyPriceBucket: monthlyPriceBucket,
                        priceBucket: priceBucket
                    };
                    return [
                        2,
                        vehiclesData
                    ];
            }
        });
    });
    return function getHomePageVehiclesData() {
        return _ref.apply(this, arguments);
    };
}();
