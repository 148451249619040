import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { getStoryBySlug } from "@cinch-nx/data-storyblok";
var isDev = process.env.STAGE_NAME !== "production";
export var getHomePageContentData = function() {
    var _ref = _async_to_generator(function() {
        var preview, story;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    preview = _arguments.length > 0 && _arguments[0] !== void 0 ? _arguments[0] : false;
                    return [
                        4,
                        getStoryBySlug("home", _object_spread({
                            version: preview ? "draft" : "published"
                        }, isDev && {
                            cv: Date.now()
                        }))
                    ];
                case 1:
                    story = _state.sent();
                    return [
                        2,
                        story !== null && story !== void 0 ? story : undefined
                    ];
            }
        });
    });
    return function getHomePageContentData() {
        return _ref.apply(this, arguments);
    };
}();
