import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { getRecentAndRecommendVehicles } from "../data/get-recent-and-recommend-vehicles";
import { tagError } from "@core/datadog-server";
export var getPersonalisationEligibility = function() {
    var _ref = _async_to_generator(function(cookies) {
        var vehicleIds, cookiePreferences, hasFunctionalCookies, parsedVehicleIds, vehicleIdsAsAnArray, recentlyViewedListings, similarListings, data, hasPreviouslyViewedCars, ref, showPersonalisedComponents, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        3,
                        ,
                        4
                    ]);
                    vehicleIds = cookies["cinch-recently-viewed-cars"];
                    cookiePreferences = cookies["cp"] ? JSON.parse(cookies["cp"]) : null;
                    hasFunctionalCookies = cookies && (cookiePreferences === null || cookiePreferences === void 0 ? void 0 : cookiePreferences.functional);
                    parsedVehicleIds = vehicleIds ? JSON.parse(vehicleIds) : [];
                    vehicleIdsAsAnArray = parsedVehicleIds.length > 0 ? parsedVehicleIds : [];
                    recentlyViewedListings = [];
                    similarListings = [];
                    if (!(vehicleIdsAsAnArray.length > 0)) return [
                        3,
                        2
                    ];
                    return [
                        4,
                        getRecentAndRecommendVehicles(vehicleIdsAsAnArray)
                    ];
                case 1:
                    data = _state.sent();
                    recentlyViewedListings = data.recentlyViewedListings;
                    similarListings = data.similarListings;
                    _state.label = 2;
                case 2:
                    hasPreviouslyViewedCars = vehicleIdsAsAnArray !== undefined && recentlyViewedListings.length > 0 && similarListings.length > 0;
                    showPersonalisedComponents = (ref = hasPreviouslyViewedCars && hasFunctionalCookies) !== null && ref !== void 0 ? ref : false;
                    return [
                        2,
                        {
                            showPersonalisedComponents: showPersonalisedComponents,
                            recentlyViewedListings: recentlyViewedListings,
                            similarListings: similarListings
                        }
                    ];
                case 3:
                    error = _state.sent();
                    if (typeof error === "string" || _instanceof(error, Error)) {
                        tagError(error);
                    }
                    console.log("there is an error with the getRecentAndRecommendVehicles call", error);
                    return [
                        2,
                        {
                            showPersonalisedComponents: false,
                            recentlyViewedListings: [],
                            similarListings: []
                        }
                    ];
                case 4:
                    return [
                        2
                    ];
            }
        });
    });
    return function getPersonalisationEligibility(cookies) {
        return _ref.apply(this, arguments);
    };
}();
