/* eslint-disable unicorn/no-useless-undefined */ import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { dashify, formatNumber } from "@cinch-labs/shared-util";
import { Env, readFromEnv } from "@cinch-nx/environments";
import { decorateOptions } from "../utils/decorate-options";
import { Datadog } from "@cinch-nx/landing-ui-shared";
var Actions = Datadog.Actions;
var formatPrice = function(price) {
    return price ? "\xa3".concat(price) : "any";
};
var VEHICLE_SEARCH_URL = "".concat(readFromEnv(Env.SearchServiceUrl), "/vehicles");
export var TOTAL_PRICE = "total-price";
export var MONTHLY_PRICE = "monthly-price";
export function getSearchButtonText(quantity) {
    return quantity === 1 ? "Search 1 car" : "Search ".concat(formatNumber(quantity), " cars");
}
export function onMakeSelected(param) {
    var setSelectedMake = param.setSelectedMake, setSelectedModel = param.setSelectedModel, setMakeOptions = param.setMakeOptions, section = param.section, handleOnChangeTracking = param.handleOnChangeTracking, event = param.event;
    var isPlaceholderSelected = event.target.value === "Select make";
    var value = event.target.value === "Any" || isPlaceholderSelected ? undefined : event.target.value;
    handleOnChangeTracking({
        trackEventLabel: "make",
        trackEventAction: "select",
        value: value,
        datadogActionName: Actions.SELECT_MAKE_OPTION,
        trackEventSection: section
    });
    setSelectedMake(value || "");
    setMakeOptions(function(makes) {
        return removeStockCountFromSelectedMake(makes, event.target.value);
    });
    setSelectedModel(undefined);
}
export function onModelSelected(param) {
    var setSelectedModel = param.setSelectedModel, handleOnChangeTracking = param.handleOnChangeTracking, event = param.event, section = param.section;
    var value = event.target.value === "Any" ? undefined : event.target.value;
    handleOnChangeTracking({
        trackEventLabel: "model",
        trackEventAction: "select",
        value: value,
        datadogActionName: Actions.SELECT_MODEL_OPTION,
        trackEventSection: section
    });
    setSelectedModel(value || "");
}
export function onSubmit(param) {
    var handleOnSubmitTracking = param.handleOnSubmitTracking, maxTotalPrice = param.maxTotalPrice, radioChecked = param.radioChecked, selectedMake = param.selectedMake, selectedModel = param.selectedModel, event = param.event, section = param.section;
    var price = radioChecked === MONTHLY_PRICE ? "monthly price: ".concat(formatPrice(maxTotalPrice)) : "total price: ".concat(formatPrice(maxTotalPrice));
    handleOnSubmitTracking(selectedMake, selectedModel, price, section);
    event.preventDefault();
    var urlParams = new URLSearchParams();
    if (maxTotalPrice && maxTotalPrice !== -1) {
        urlParams.append("toPrice", maxTotalPrice.toString());
    }
    if (radioChecked === MONTHLY_PRICE) {
        urlParams.append("useMonthly", "true");
    }
    var url = "/used-cars";
    if (selectedMake && selectedMake !== "any") {
        url += "/".concat(dashify(selectedMake));
    }
    if (selectedModel && selectedModel !== "any" && selectedMake && selectedMake !== "any") {
        url += "/".concat(dashify(selectedModel));
    }
    window.location.assign("".concat(url, "?").concat(urlParams.toString()));
}
export function updateSearchButtonLabel(_) {
    return _updateSearchButtonLabel.apply(this, arguments);
}
function _updateSearchButtonLabel() {
    _updateSearchButtonLabel = _async_to_generator(function(param) {
        var selectedMake, selectedModel, radioChecked, maxTotalPrice, setButtonText, urlParams, response, buttonText;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    selectedMake = param.selectedMake, selectedModel = param.selectedModel, radioChecked = param.radioChecked, maxTotalPrice = param.maxTotalPrice, setButtonText = param.setButtonText;
                    urlParams = new URLSearchParams({
                        pageSize: "0"
                    });
                    if (selectedMake && selectedMake !== "any") {
                        urlParams.append("make", selectedMake);
                    }
                    if (selectedModel && selectedModel !== "any" && selectedMake && selectedMake !== "any") {
                        urlParams.append("model", selectedModel);
                    }
                    if (radioChecked === MONTHLY_PRICE) {
                        urlParams.append("useMonthly", "true");
                    }
                    if (maxTotalPrice !== undefined) {
                        urlParams.append("toPrice", maxTotalPrice.toString());
                    }
                    return [
                        4,
                        getSearchResults(urlParams)
                    ];
                case 1:
                    response = _state.sent();
                    buttonText = getSearchButtonText(response.searchResultsCount);
                    setButtonText(buttonText);
                    return [
                        2
                    ];
            }
        });
    });
    return _updateSearchButtonLabel.apply(this, arguments);
}
export function updateStockLevels(_) {
    return _updateStockLevels.apply(this, arguments);
}
function _updateStockLevels() {
    _updateStockLevels = _async_to_generator(function(param) {
        var selectedMake, selectedModel, radioChecked, maxTotalPrice, totalStock, setMakeOptions, setModelOptions, setPriceBucket, setMonthlyPriceBucket, urlParams, response, makes, urlParams1, data, makeOptions, make, modelTotalStock, ref, modelOptions, decoratedModelOptions;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    selectedMake = param.selectedMake, selectedModel = param.selectedModel, radioChecked = param.radioChecked, maxTotalPrice = param.maxTotalPrice, totalStock = param.totalStock, setMakeOptions = param.setMakeOptions, setModelOptions = param.setModelOptions, setPriceBucket = param.setPriceBucket, setMonthlyPriceBucket = param.setMonthlyPriceBucket;
                    urlParams = new URLSearchParams();
                    if (selectedMake && selectedMake !== "any") {
                        urlParams.append("make", selectedMake);
                    }
                    if (selectedModel && selectedModel !== "any" && selectedMake && selectedMake !== "any") {
                        urlParams.append("model", selectedModel);
                    }
                    if (radioChecked === MONTHLY_PRICE) {
                        urlParams.append("useMonthly", "true");
                    }
                    if (maxTotalPrice !== undefined) {
                        urlParams.append("toPrice", maxTotalPrice.toString());
                    }
                    return [
                        4,
                        getSearchResults(urlParams)
                    ];
                case 1:
                    response = _state.sent();
                    makes = response.facets.makes;
                    if (!(maxTotalPrice !== undefined)) return [
                        3,
                        3
                    ];
                    urlParams1 = new URLSearchParams({
                        perPage: "0"
                    });
                    if (radioChecked === MONTHLY_PRICE) {
                        urlParams1.append("useMonthly", "true");
                    }
                    urlParams1.append("toPrice", maxTotalPrice.toString());
                    return [
                        4,
                        getSearchResults(urlParams1)
                    ];
                case 2:
                    data = _state.sent();
                    totalStock = data.searchResultsCount;
                    _state.label = 3;
                case 3:
                    makeOptions = decorateOptions(makes.map(function(data) {
                        return toMakes(data);
                    }).map(function(make) {
                        var label = "".concat(make.name);
                        if (make.name !== selectedMake) {
                            label += " (".concat(formatNumber(make.stock), ")");
                        }
                        return {
                            label: label,
                            value: make.name,
                            count: "".concat(make.stock)
                        };
                    }), totalStock || 0);
                    setMakeOptions(makeOptions);
                    if (selectedMake && selectedMake !== "any") {
                        make = makes.map(function(data) {
                            return toMakes(data);
                        }).find(function(make) {
                            return make.name === selectedMake;
                        });
                        modelTotalStock = response.searchResultsCount;
                        ;
                        modelOptions = modelToOptions((ref = make === null || make === void 0 ? void 0 : make.models) !== null && ref !== void 0 ? ref : [], selectedModel);
                        decoratedModelOptions = decorateOptions(modelOptions, modelTotalStock || 0);
                        setModelOptions(decoratedModelOptions);
                    }
                    setPriceBucket(response.facets.priceBucket);
                    setMonthlyPriceBucket(response.facets.monthlyPriceBucket);
                    return [
                        2
                    ];
            }
        });
    });
    return _updateStockLevels.apply(this, arguments);
}
export function updateModelOptions(param) {
    var selectedMake = param.selectedMake, selectedModel = param.selectedModel, makes = param.makes, setModelOptions = param.setModelOptions;
    if (selectedMake && !/any/gi.test(selectedMake)) {
        var make = makes.find(function(make) {
            return make.name === selectedMake;
        });
        setModelOptions(modelToOptions(make.models, selectedModel));
        return;
    }
    setModelOptions([]);
}
function modelToOptions(models, selectedModel) {
    return models.map(function(model) {
        var label = "".concat(model.name);
        if (model.name !== selectedModel) {
            label += " (".concat(formatNumber(model.stock), ")");
        }
        return {
            label: label,
            value: model.name,
            count: "".concat(model.stock)
        };
    });
}
export function getSearchResults(urlParams) {
    return _getSearchResults.apply(this, arguments);
}
function _getSearchResults() {
    _getSearchResults = _async_to_generator(function(urlParams) {
        var url, request, response;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    url = "".concat(VEHICLE_SEARCH_URL, "?").concat(urlParams.toString());
                    return [
                        4,
                        fetch(url)
                    ];
                case 1:
                    request = _state.sent();
                    return [
                        4,
                        request.json()
                    ];
                case 2:
                    response = _state.sent();
                    return [
                        2,
                        response
                    ];
            }
        });
    });
    return _getSearchResults.apply(this, arguments);
}
export function toMakes(make) {
    return {
        name: make.name,
        stock: make.doc_count,
        models: make.models.map(function(model) {
            return {
                name: model.name,
                stock: model.doc_count
            };
        })
    };
}
function removeStockCountFromSelectedMake(makes, selectedMake) {
    if (selectedMake && selectedMake !== "any") {
        return makes.map(function(make) {
            if (make.value === selectedMake) {
                make.label = selectedMake;
            }
            return make;
        });
    }
    return makes;
}
