import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { Env, readFromEnv } from "@cinch-nx/environments";
var MAX_VEHICLE_LISTINGS = 6;
export var SEARCH_URL = readFromEnv(Env.SearchServiceUrl);
export var getRecentAndRecommendVehicles = function() {
    var _ref = _async_to_generator(function(vehicleIds) {
        var ref, recentVehicleResults, similarVehicleResults, data, e, similarVehicleData, e1, vehicleListings, similarVehicleListing, orderedVehicles;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        3,
                        ,
                        4
                    ]);
                    return [
                        4,
                        fetch("".concat(SEARCH_URL, "/vehicles?vehicleIds=").concat(vehicleIds.join(","), "&pageSize=").concat(MAX_VEHICLE_LISTINGS))
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        4,
                        data.json()
                    ];
                case 2:
                    recentVehicleResults = _state.sent();
                    return [
                        3,
                        4
                    ];
                case 3:
                    e = _state.sent();
                    throw new Error("Something went wrong with the fetching of vehicle listings");
                case 4:
                    _state.trys.push([
                        4,
                        7,
                        ,
                        8
                    ]);
                    return [
                        4,
                        fetch("".concat(SEARCH_URL, "/similar?vehicleId=").concat(vehicleIds[0], "&pageSize=").concat(MAX_VEHICLE_LISTINGS))
                    ];
                case 5:
                    similarVehicleData = _state.sent();
                    return [
                        4,
                        similarVehicleData.json()
                    ];
                case 6:
                    similarVehicleResults = _state.sent();
                    return [
                        3,
                        8
                    ];
                case 7:
                    e1 = _state.sent();
                    throw new Error("Something went wrong with the fetching of similiar vehicles");
                case 8:
                    vehicleListings = (recentVehicleResults === null || recentVehicleResults === void 0 ? void 0 : (ref = recentVehicleResults.vehicleListings) === null || ref === void 0 ? void 0 : ref.filter(function(vehicle) {
                        return !vehicle.isReserved;
                    })) || [];
                    similarVehicleListing = (similarVehicleResults === null || similarVehicleResults === void 0 ? void 0 : similarVehicleResults.vehicleListings) || [];
                    orderedVehicles = vehicleIds.reduce(function(vehicles, vehicleId) {
                        var vehicleToOrder = vehicleListings.find(function(recentVehicle) {
                            return recentVehicle.vehicleId === vehicleId;
                        });
                        if (vehicleToOrder) {
                            vehicles = _to_consumable_array(vehicles).concat([
                                vehicleToOrder
                            ]);
                        }
                        return vehicles;
                    }, []);
                    return [
                        2,
                        {
                            recentlyViewedListings: orderedVehicles,
                            similarListings: similarVehicleListing
                        }
                    ];
            }
        });
    });
    return function getRecentAndRecommendVehicles(vehicleIds) {
        return _ref.apply(this, arguments);
    };
}();
