import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { compareObjects, cookieBuilder, getChannel, getCookie, getParamValue } from "./shared-customer-journey-cookies.helper";
var ref, ref1;
export var sharedCustomerJourneyCookies = function() {
    return {
        commissionGroup: (ref = getParamValue("parts")) !== null && ref !== void 0 ? ref : "DEFAULT",
        channel: getChannel(),
        awc: getParamValue("awc"),
        voucher: (ref1 = getParamValue("voucher")) !== null && ref1 !== void 0 ? ref1 : ""
    };
};
export var saveCustomerJourneyCookie = function() {
    if ("object" !== "undefined") {
        var isAwcInQueryParameter = !!getParamValue("awc");
        var hasAWINCookie = !!getCookie({
            name: "awin"
        });
        if (isAwcInQueryParameter) {
            var buildCookieData = sharedCustomerJourneyCookies();
            document.cookie = cookieBuilder("awin", buildCookieData, 30);
            return {
                data: buildCookieData,
                message: "Cookie successfully saved"
            };
        }
        if (!isAwcInQueryParameter && hasAWINCookie) {
            var oldCookie = JSON.parse(getCookie({
                name: "awin"
            }));
            var newCookie = _object_spread_props(_object_spread({}, oldCookie), {
                channel: getChannel()
            });
            if (compareObjects(oldCookie, newCookie)) {
                return {
                    data: oldCookie,
                    message: "Cookie value not updated"
                };
            }
            document.cookie = cookieBuilder("awin", newCookie, 30);
            return {
                data: newCookie,
                message: "Cookie successfully updated"
            };
        }
        return {
            data: null,
            message: "unable to save cookie"
        };
    } else {
        return {
            data: null,
            message: "window is undefined"
        };
    }
};
export var getCustomerJourneyCookie = function() {
    var awinCustomerJourneyCookie = getCookie({
        name: "awin"
    });
    var hasCustomerJourney = !!awinCustomerJourneyCookie;
    if (hasCustomerJourney) {
        return {
            data: JSON.parse(awinCustomerJourneyCookie),
            message: ""
        };
    }
    return {
        data: null,
        message: "unable to get customer journey cookie"
    };
};
export var clearCustomerJourneyCookie = function() {
    var hasCustomerJourney = !!getCookie({
        name: "awin"
    });
    if (hasCustomerJourney) {
        document.cookie = "awin=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
        return {
            message: "Cleared customer journey cookie"
        };
    }
    return {
        message: "Unable to find customer journey cookie"
    };
};
