import { EventNames, TrackingEventTypes } from "@cinch-nx/shared-analytics";
import { datadogRum } from "@datadog/browser-rum";
import { Datadog } from "@cinch-nx/landing-ui-shared";
var Actions = Datadog.Actions, Context = Datadog.Context;
var getValueOrAny = function(value) {
    return value ? value : "any";
};
export function createTrackers(trackEvent) {
    return {
        onChange: function onChange(param) {
            var trackEventLabel = param.trackEventLabel, trackEventAction = param.trackEventAction, value = param.value, datadogActionName = param.datadogActionName, trackEventSection = param.trackEventSection;
            trackEvent({
                eventName: "Homepage ".concat(trackEventLabel, " dropdown"),
                type: TrackingEventTypes.ADOBE,
                data: {
                    event: {
                        name: EventNames.click,
                        category: "hero",
                        action: trackEventAction,
                        label: trackEventLabel,
                        value: value === null || value === void 0 ? void 0 : value.toLowerCase(),
                        section: trackEventSection === null || trackEventSection === void 0 ? void 0 : trackEventSection.toLowerCase()
                    }
                }
            });
            datadogRum.addAction(datadogActionName, {
                service: Context.HERO_SEARCH,
                squad: Context.SQUAD,
                page: window.location.pathname,
                value: value === null || value === void 0 ? void 0 : value.toLowerCase()
            });
        },
        onSubmit: function onSubmit(selectedMake, selectedModel, price, trackEventSection) {
            var trackEventName = "Homepage filter search all cars";
            var actionName = "";
            var searchTrackEventValue = "search all cars make: ".concat(getValueOrAny(selectedMake), ", model: ").concat(getValueOrAny(selectedModel), ", ").concat(getValueOrAny(price));
            if (selectedMake) {
                trackEventName = "Homepage filter search now";
                actionName = selectedModel ? "".concat(selectedMake, ",").concat(selectedModel) : "".concat(selectedMake);
            }
            trackEvent({
                eventName: trackEventName,
                type: TrackingEventTypes.ADOBE,
                data: {
                    action: actionName,
                    event: {
                        name: EventNames.click,
                        category: "hero",
                        action: "submit",
                        label: "search all cars",
                        value: searchTrackEventValue,
                        section: trackEventSection === null || trackEventSection === void 0 ? void 0 : trackEventSection.toLowerCase()
                    }
                }
            });
            datadogRum.addAction(Actions.CLICK_SEARCH_CTA, {
                service: Context.HERO_SEARCH,
                squad: Context.SQUAD,
                page: window.location.pathname,
                value: searchTrackEventValue
            });
        }
    };
}
