import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { getReviews } from "@cinch-nx/data-trustpilot";
import { getHomePageContentData } from "./data/content";
import { getHomePageVehiclesData } from "./data/vehicles";
import { getPersonalisationEligibility } from "./helpers/get-personalisation-eligibility";
import { setTag, traceFunction } from "@core/datadog-server";
import { getAdobeTargetSSR } from "@cinch-labs/adobe";
function getServerSidePropsfn(ctx) {
    return _getServerSidePropsfn.apply(this, arguments);
}
function _getServerSidePropsfn() {
    _getServerSidePropsfn = _async_to_generator(function(ctx) {
        var isInsideStoryblok, ref, vehiclesData, story, trustpilotData, ref1, showPersonalisedComponents, similarListings, recentlyViewedListings, homepageProdTest;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    isInsideStoryblok = !!ctx.preview;
                    return [
                        4,
                        Promise.all([
                            getHomePageVehiclesData(),
                            getHomePageContentData(isInsideStoryblok),
                            getReviews(),
                            getPersonalisationEligibility(ctx.req.cookies)
                        ])
                    ];
                case 1:
                    ref = _sliced_to_array.apply(void 0, [
                        _state.sent(),
                        4
                    ]), vehiclesData = ref[0], story = ref[1], trustpilotData = ref[2], ref1 = ref[3], showPersonalisedComponents = ref1.showPersonalisedComponents, similarListings = ref1.similarListings, recentlyViewedListings = ref1.recentlyViewedListings;
                    if (!story) {
                        throw new Error("Unable to fetch `home` story from storyblok");
                    }
                    setTag("response.code", 200);
                    setTag("page.name", "homepage");
                    setTag("page.url", ctx.resolvedUrl);
                    setTag("page.rendered", "SSR");
                    setTag("page.params", JSON.stringify({
                        preview: isInsideStoryblok,
                        query: ctx.query,
                        showPersonalisedComponents: showPersonalisedComponents
                    }, null, 2));
                    return [
                        4,
                        getAdobeTargetSSR(ctx, "HOMEPAGE_SSR_TEST", "SSR_PROD_TEST")
                    ];
                case 2:
                    homepageProdTest = _state.sent();
                    setTag("page.ssrAdobeTest", JSON.stringify(homepageProdTest, null, 2));
                    return [
                        2,
                        {
                            props: {
                                story: story,
                                vehiclesData: vehiclesData,
                                showPersonalisedComponents: showPersonalisedComponents,
                                trustpilotData: trustpilotData,
                                preview: isInsideStoryblok,
                                recentlyViewedListings: recentlyViewedListings,
                                similarListings: similarListings,
                                generatedAt: Date.now().toString(),
                                layoutProps: {
                                    hasPromoBanner: false
                                }
                            }
                        }
                    ];
            }
        });
    });
    return _getServerSidePropsfn.apply(this, arguments);
}
export var getServerSideProps = traceFunction("homepage.getServerSideProps", getServerSidePropsfn);
