import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
function convertKeyCountIntoPriceRangeWithFacetCount(key, count) {
    var ref = _sliced_to_array(key.split("-"), 2), from = ref[0], to = ref[1];
    var fromAsNumber = Number.parseInt(from, 10);
    var toAsNumber = Number.parseInt(to, 10);
    var response = {
        count: count
    };
    if (!Number.isNaN(fromAsNumber)) response.from = fromAsNumber;
    if (!Number.isNaN(toAsNumber)) response.to = toAsNumber;
    return response;
}
function isDefined(value) {
    return typeof value !== "undefined" && value !== null;
}
/**
 * convertIntoRangeFacet accepts rangeLikeFacet is a key pair value with key in pattern of <value>-<value> e,g, 7000-8000
 */ export function convertIntoRangeFacet(rangeLikeFacet) {
    return Object.entries(rangeLikeFacet).map(function(param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], count = _param[1];
        var rangeFacet = convertKeyCountIntoPriceRangeWithFacetCount(key, count);
        return key ? rangeFacet : undefined;
    })// eslint-disable-next-line unicorn/no-array-callback-reference
    .filter(isDefined).sort(function(a, b) {
        if (!a.to) return 1;
        if (!b.to) return -1;
        return a.to - b.to;
    });
}
