import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { readFromEnv } from "@cinch-nx/environments";
import { z } from "zod";
import { tagError, setTag, traceFunction } from "@core/datadog-server";
var Review = z.object({
    starRating: z.number(),
    title: z.string(),
    description: z.string(),
    customerName: z.string(),
    date: z.string().datetime(),
    link: z.string().url()
});
var TrustpilotReviewsResponse = z.object({
    trustScore: z.number(),
    stars: z.number(),
    total: z.number(),
    starRatingLabel: z.string(),
    reviews: z.array(Review).min(3).max(3)
});
var fallbackResponse = {
    trustScore: 4.3,
    total: 35663,
    stars: 4.5,
    starRatingLabel: "Excellent",
    reviews: [
        {
            starRating: 5,
            title: "Everything is so easy and…",
            description: "Everything is so easy and straightforward to do and buying a car has never been so easy and stress free because of cinch’s 14 day returns and six month warranty and our delivery guy lucian stoica (lucs thank you)was a really nice bloke and went through the car with us and answered any questions we had.Couldn’t have gone any better definitely the new way to buy a car.Well done cinch.",
            customerName: "Mark Perry",
            date: "01 May 2024",
            link: "https://uk.trustpilot.com/review/cinch.co.uk"
        },
        {
            starRating: 5,
            title: "So pleased with the service people…",
            description: "So pleased with the service people should cinch it great experience throughout ,professional.\n        The only downside for me was the \xa399 admin fee especially because I sent my first car back within 14 days because I changed my mind, it should be wavered 2nd time.\n        I am so pleases with my new car now, thank you so much",
            customerName: "Lisa Shelley",
            date: "01 May 2024",
            link: "https://uk.trustpilot.com/review/cinch.co.uk"
        },
        {
            starRating: 5,
            title: "This has been an amazing experience",
            description: "This has been an amazing experience! So straightforward, well looked after every step of the way, including a welcome video from the finance company via cinch. Viru delivered the car and was simply charming \uD83D\uDE0A I would definitely recommend buying through cinch, thanks everyone! \uD83D\uDC4F\uD83D\uDC4D\uD83D\uDC4F",
            customerName: "K from London",
            date: "01 May 2024",
            link: "https://uk.trustpilot.com/review/cinch.co.uk"
        }, 
    ]
};
function getTrustpilotReviewsFn() {
    return _getTrustpilotReviewsFn.apply(this, arguments);
}
function _getTrustpilotReviewsFn() {
    _getTrustpilotReviewsFn = _async_to_generator(function() {
        var url, response, ref, _, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        3,
                        ,
                        4
                    ]);
                    url = "".concat(readFromEnv("TRUSTPILOT_URL"), "/trustpilot-get-reviews");
                    return [
                        4,
                        fetch(url)
                    ];
                case 1:
                    response = _state.sent();
                    setTag("request.url", url);
                    setTag("request.method", "GET");
                    setTag("request.status", response.status);
                    setTag("request.content-length", (ref = response.headers.get("content-length")) !== null && ref !== void 0 ? ref : "0");
                    _ = TrustpilotReviewsResponse.parse;
                    return [
                        4,
                        response.json()
                    ];
                case 2:
                    return [
                        2,
                        _.apply(TrustpilotReviewsResponse, [
                            _state.sent()
                        ])
                    ];
                case 3:
                    error = _state.sent();
                    if (typeof error === "string" || _instanceof(error, Error)) {
                        tagError(error);
                    }
                    console.error(error);
                    return [
                        2,
                        fallbackResponse
                    ];
                case 4:
                    return [
                        2
                    ];
            }
        });
    });
    return _getTrustpilotReviewsFn.apply(this, arguments);
}
export var getTrustpilotReviews = traceFunction("trustpilot.getTrustpilotReviews", getTrustpilotReviewsFn);
