import { createContext, useContext } from "react";
var defaultValues = {
    starRatingLabel: "Excellent",
    totalReviews: 10000,
    starRating: 4.5
};
export var TrustpilotScoreContext = createContext(defaultValues);
export function useTrustpilotScoreContext() {
    var context = useContext(TrustpilotScoreContext);
    if (context === undefined) {
        throw new Error("useTrustpilotScore must be used within a provider");
    }
    return context;
}
