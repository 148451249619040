import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var getToPriceFacetCountsWhenFromPriceIs = function(priceRanges) {
    var selectedValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : -1;
    var tally = 0;
    return _to_consumable_array(priceRanges).filter(function(priceRange) {
        return selectedValue === -1 || (priceRange.to ? priceRange.to > selectedValue : false);
    }).map(function(priceRange) {
        return {
            value: priceRange.to,
            doc_count: tally += priceRange.count
        };
    }).reduce(function(accumulator, currentPriceRange) {
        var currentValue = currentPriceRange.value;
        if (currentValue !== undefined) {
            accumulator[String(currentValue)] = currentPriceRange.doc_count;
        }
        return accumulator;
    }, {});
};
