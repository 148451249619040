import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { VEHICLE_SEARCH_URL } from "libs/data/search/src/lib/constants";
export var getSearchAPIData = function() {
    var _ref = _async_to_generator(function() {
        var response;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        fetch(VEHICLE_SEARCH_URL).then(function(res) {
                            return res.json();
                        })
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        2,
                        response
                    ];
            }
        });
    });
    return function getSearchAPIData() {
        return _ref.apply(this, arguments);
    };
}();
