import _object_spread from "@swc/helpers/src/_object_spread.mjs";
export function fillInMissingRangeFacets(staticIncrements, dynamicRangeFacets) {
    var updatedFacets = _object_spread({}, dynamicRangeFacets);
    staticIncrements.forEach(function(param) {
        var rangeKey = param.rangeKey;
        if (dynamicRangeFacets[rangeKey] === undefined) {
            updatedFacets[rangeKey] = 0;
        }
    });
    return updatedFacets;
}
